<template>
    <div class="detector-index-page">
        <full-loading ref="fullLoading"></full-loading>
        <!-- <asset-tabs :active="'detector'"></asset-tabs> -->
        <template v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) < 0">
        <div class="page-tools-content">
            <div class="left">
                <el-button type="grey" size="small" round icon="el-icon-plus" v-permission="`tools:detectorRouter:addDoubleDoc`" @click="addTaskBtnEvent(2)">{{$t('tools.tools_detectorIndex_page_btn_1')}}</el-button>
                <el-button size="small" round icon="el-icon-plus" v-permission="`tools:detectorRouter:addSingleDoc`" @click="addTaskBtnEvent(1)">{{$t('tools.tools_detectorIndex_page_btn_2')}}</el-button>
                <el-button size="small" round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage('reload')">{{$t('tools.tools_detectorIndex_page_btn_3')}}</el-button>
            </div>
            <div class="right">
                <div style="width:220px;">
                    <el-input :placeholder="$t('tools.tools_detectorIndex_page_input_placeholder_1')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div style="width:124px;margin-left:10px;">
                    <el-select v-model="searchForm.sort" size="small" :placeholder="$t('tools.tools_detectorIndex_page_select_placeholder_1')">
                        <el-option key="1" value="1" :label="$t('tools.tools_detectorIndex_page_select_option_label_1')"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <card-list v-if="list.length>0"
            :type="`detector`"
            :list="list"
            @action="executeAction"></card-list>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info" v-if="queryType === 'search'">{{$t('tools.tools_detectorIndex_page_tips_1')}}</div>
                <div class="info" v-else>{{$t('tools.tools_detectorIndex_page_tips_2')}}</div>
                <div>
                    <el-button type="primary" size="small" round icon="el-icon-plus" v-permission="`tools:detectorRouter:addDoubleDoc`" @click="addTaskBtnEvent(2)">{{$t('tools.tools_detectorIndex_page_btn_1')}}</el-button>
                    <el-button size="small" round icon="el-icon-plus" v-permission="`tools:detectorRouter:addSingleDoc`" @click="addTaskBtnEvent(1)">{{$t('tools.tools_detectorIndex_page_btn_2')}}</el-button>
                </div>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        </template>
        <template v-else>
            <upgrade-pro style="margin-top: 230px;"></upgrade-pro>
        </template>
        <el-dialog :title="$t('tools.tools_detectorIndex_page_dialog_1_title')" :visible.sync="dialog.show" destroy-on-close @close="typeRadioSelected = 'aligned'" :width="'712px'" top="20px">
            <div class="dialog-content" v-if="userInfo && userInfo.featureConfig">
                <el-image style="display:none;" ref="qaFileTypeExample"
                    src="https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/excel.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195067&Signature=SLQhEo%2BAhrmElVC4qsbdOVU%2B9To%3D"
                    :preview-src-list="[
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/excel.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195067&Signature=SLQhEo%2BAhrmElVC4qsbdOVU%2B9To%3D',
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/external1.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195092&Signature=uJNP%2ByxOd9oojRqNZbYxxoRs7o0%3D',
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/external2.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195112&Signature=636q3UmMIw9ikmgoS5DklS1JNOM%3D',
                        'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/external3.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=101634195136&Signature=NdgUEp6bUo%2BlTioFMHEZqUbPMGw%3D'
                    ]"></el-image>
                <template v-if="dialog.type === 1">
                    <type-radio :list="typeRadioOptions" :active="typeRadioSelected" @change="typeRadioChange" style="justify-content: center;margin-bottom:15px;"></type-radio>
                    <div class="tips" v-if="typeRadioSelected === 'aligned'">
                        {{$t('tools.tools_detectorIndex_page_tips_3', {type: dialog.supportAlignedFiles})}}<a href="javascript:;" @click="$refs.qaFileTypeExample.clickHandler()">{{$t('tools.tools_detectorIndex_page_tips_4')}}</a>{{$t('tools.tools_detectorIndex_page_tips_5', {size: userInfo.featureConfig.singleFileSize})}}
                        <el-tooltip placement="top">
                            <div slot="content" style="max-width: 300px;line-height: 1.5;">{{$t('tools.tools_detectorIndex_page_tips_6')}}</div>
                            <span class="margin-l-10 color-link cursor-pointer">{{$t('tools.tools_detectorIndex_page_tips_7')}}</span>
                        </el-tooltip>
                    </div>
                    <div class="tips" v-else>
                        {{$t('tools.tools_detectorIndex_page_tips_8', {type: dialog.supportUnalignedFiles, size: userInfo.featureConfig.singleFileSize})}}
                        <el-tooltip placement="top">
                            <div slot="content" style="max-width: 300px;line-height: 1.5;">{{$t('tools.tools_detectorIndex_page_tips_9')}}</div>
                            <span class="margin-l-10 color-link cursor-pointer">{{$t('tools.tools_detectorIndex_page_tips_7')}}</span>
                        </el-tooltip>
                    </div>
                    <div class="upload-files-content">
                        <upload-files 
                            ref="sourceUpload"
                            :title="$t('tools.tools_detectorIndex_page_tips_10')" 
                            :size="userInfo.featureConfig.singleFileSize"
                            :accept="typeRadioSelected === 'aligned'?dialog.acceptAligned: dialog.acceptUnaligned" 
                            :multiple="false"
                            :params="{}"></upload-files>
                    </div>
                </template>
                <template v-else>
                    <div class="tips">
                        {{$t('tools.tools_detectorIndex_page_tips_8', {type: dialog.supportUnalignedFiles, size: userInfo.featureConfig.singleFileSize})}}
                        <el-tooltip placement="top">
                            <div slot="content" style="max-width: 300px;line-height: 1.5;">{{$t('tools.tools_detectorIndex_page_tips_9')}}</div>
                            <span class="margin-l-10 color-link cursor-pointer">{{$t('tools.tools_detectorIndex_page_tips_7')}}</span>
                        </el-tooltip>
                    </div>
                    <div class="upload-files-content-2">
                        <div><!-- .xlsx,.xls,.csv,.rtf, -->
                            <upload-files 
                                ref="sourceUpload"
                                :title="$t('tools.tools_detectorIndex_page_tips_11')" 
                                :size="userInfo.featureConfig.singleFileSize"
                                :accept="dialog.acceptUnaligned" 
                                :multiple="false"
                                :params="{}"></upload-files>
                        </div>
                        <div>
                            <upload-files 
                                ref="targetUpload"
                                :title="$t('tools.tools_detectorIndex_page_tips_12')"
                                :size="userInfo.featureConfig.singleFileSize"
                                :accept="dialog.acceptUnaligned"
                                :multiple="false"
                                :params="{}"></upload-files>
                        </div>
                    </div>
                </template>
                <div class="language-direction">
                    <div style="width:110px;">
                        <el-select v-model="dialog.source" placeholder="" size="small" @change="sourceSelectChange">
                            <template v-for="value in LANGUAGE_DICT">
                                <el-option :key="value.key" :value="value.key" :label="value.name"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div @click="exchangeLanguage" style="cursor: pointer;">
                        <el-tooltip :content="$t('tools.tools_detectorIndex_page_tips_13')" placement="top">
                            <svg-icon name="ib-transition"></svg-icon>
                        </el-tooltip>
                    </div>
                    <div style="width:110px;">
                        <el-select v-model="dialog.target" placeholder="" size="small" @change="targetSelectChange">
                            <template v-for="value in LANGUAGE_DICT">
                                <el-option :key="value.key" :value="value.key" :label="value.name" :disabled="value.key === dialog.source"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
                <div class="detector-setting">
                    <next-collapse :title="$t('tools.tools_detectorIndex_page_tips_14')" :show="true">
                        <el-checkbox-group v-model="selectedEngine">
                            <div class="option-list">
                                <template v-for="(item,index) in engineConfig">
                                    <div class="item" :key="index" v-if="item.key !== 'robot'">
                                        <el-checkbox :key="item.key" :value="item.key" :label="item.key">{{item.name}}</el-checkbox>
                                    </div>
                                </template>
                            </div>
                        </el-checkbox-group>
                        <el-divider></el-divider>
                        <el-form :inline="true" label-width="70px">
                          <el-form-item :label="$t('tools.tools_detectorIndex_page_dialog_1_form_item_label_1')" style="margin-right: 10px;">
                              <el-select v-model="spotCheckValue" :placeholder="$t('tools.tools_detectorIndex_page_dialog_1_select_placeholder_1')" style="width: 120px;">
                                  <el-option v-for="item in spotCheckOptions" :key="item" :value="item" :label="`${item}%`"></el-option>
                              </el-select>
                              <el-tooltip :content="$t('tools.tools_detectorIndex_page_dialog_1_tips_1')" placement="top">
                                <svg-icon name="ib-question" class-name="fonts-20 margin-l-10 color-555"></svg-icon>
                              </el-tooltip>
                          </el-form-item>
                          <el-form-item :label="$t('tools.tools_detectorIndex_page_dialog_1_form_item_label_2')">
                              <el-select v-model="shortSentValue" :placeholder="$t('tools.tools_detectorIndex_page_dialog_1_select_placeholder_2')" style="width: 120px;">
                                  <el-option v-for="item in shortSentOptions" :key="item" :value="item" :label="$t('tools.tools_detectorIndex_page_dialog_1_option_label_1', {size: item})"></el-option>
                              </el-select>
                              <el-tooltip :content="$t('tools.tools_detectorIndex_page_dialog_1_tips_2')" placement="top">
                                <svg-icon name="ib-question" class-name="fonts-20 margin-l-10 color-555"></svg-icon>
                              </el-tooltip>
                          </el-form-item>
                        </el-form>
                    </next-collapse>
                </div>
            </div>
            <div slot="footer" style="text-align: center;">
                <el-button round plain size="small" @click="dialog.show = false">{{$t('tools.tools_detectorIndex_page_dialog_1_btn_1')}}</el-button>
                <el-button round type="primary" size="small" :loading="dialog.loading" @click="startTaskEvent">{{$t('tools.tools_detectorIndex_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>

        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteTask"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import tools from '@/utils/tools';
import UpgradePro from '@/components/UpgradePro';
import TypeRadio from '@/components/TypeRadio';
import FullLoading from '@/components/FullLoading';
import AssetTabs from '../components/Tabs';
import CardList from '../components/CardList';
import UploadFiles from '@/components/UploadOss';
import AlertDialog from '@/components/AlertDialog';
import NextCollapse from '@/components/NextCollapse';
import { projectApi, toolsApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
export default {
    components: { TypeRadio, FullLoading, AssetTabs, CardList, UploadFiles, AlertDialog, NextCollapse, UpgradePro },
    computed:{
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    data() {
        return {
            refreshLoading: false,
            listTimer: null,
            alertInfo:{
                message: this.$t('tools.tools_detectorIndex_page_data_alertInfo_message'),
                type: 'confirm', //alert ,confirm
                buttonName: this.$t('tools.tools_detectorIndex_page_data_alertInfo_buttonName'),
                buttonType: 'danger',
            },
            spotCheckOptions:[10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            shortSentOptions:[5, 10, 15, 20],

            engineConfig: [],
            dialog: {
                uploadFileURL: toolsApi.uploadFile,
                show: false,
                source: 'zh',
                target: 'en',
                loading: false,
                acceptAligned: '.docx,.doc,.xlsx,.xls,.tmx',
                supportAlignedFiles: 'xlsx/xls/tmx',
                acceptUnaligned: '.docx,.doc,.txt', // ,.pdf
                supportUnalignedFiles: 'docx/doc/txt', // /pdf
            },
            searchForm: {
                kw: '',
                sort: '1'
            },
            queryType: 'init',
            list: [],
            total: 0,
            pageSize: 20,
            pageNumber: 1,
            searchTimer: null,
            selectedTaskIds: [],
            typeRadioOptions:[{key:'aligned',name: this.$t('tools.tools_detectorIndex_page_data_typeRadioOptions_name_1')},{key:'unaligned',name: this.$t('tools.tools_detectorIndex_page_data_typeRadioOptions_name_2')}],
            typeRadioSelected:'aligned', 
            selectedEngine: [],
            spotCheckValue: 10,
            shortSentValue: 10,

        }
    },

    methods: {
        initPage(type){
            if(type === 'init'){
                this.$refs.fullLoading.setShow();
            }
            if(type === 'reload'){
                this.refreshLoading = true;
            }
            let url = `${toolsApi.queryMTITaskList}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}&keyword=${encodeURI(this.searchForm.kw)}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            }).finally(()=>{
                this.$refs.fullLoading.setHide();
                this.refreshLoading = false;
            })
        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        },
        initEngineConfig(){
            this.selectedEngine = [];
            let url = `${toolsApi.queryMTIEngineList}?sourceLang=${this.dialog.source}&targetLang=${this.dialog.target}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.engineConfig = res.data;
                }
            });
        },
        startTaskEvent(){
            let sourceFiles = this.$refs.sourceUpload.getOssFiles();
            let targetFiles = this.dialog.type === 2 ? this.$refs.targetUpload.getOssFiles() : [];
            if(sourceFiles.length === 0){
                this.$message.error(this.dialog.type === 1 ? this.$t('tools.tools_detectorIndex_page_method_startTaskEvent_message_1') : this.$t('tools.tools_detectorIndex_page_method_startTaskEvent_message_2'));
                return;
            }
            if(this.dialog.type ===2){
                if(targetFiles.length === 0){
                    this.$message.error(this.$t('tools.tools_detectorIndex_page_method_startTaskEvent_message_3'));
                    return;
                }
            }
            if(this.selectedEngine.length === 0){
                this.$message.error(this.$t('tools.tools_detectorIndex_page_method_startTaskEvent_message_4'));
                return;
            }

            let originalFiles = {};
            if(this.dialog.type === 2){
                originalFiles = {
                    source: sourceFiles,
                    target: targetFiles,
                }
            }else{
                originalFiles = {
                    bilingual: sourceFiles,
                }
            }
            
            this.dialog.loading = true;
            let url = toolsApi.startMTITask;
            this.$ajax.post(url,{
                sourceLang: this.dialog.source,
                targetLang: this.dialog.target,
                isAligned: this.dialog.type === 1 ? (this.typeRadioSelected === 'aligned' ? true : false) : false,
                toolType: 'MTI',
                title: `${sourceFiles[0].fileName}`,
                engines: this.selectedEngine,
                sentenceLengthMin: this.shortSentValue,
                samplingRate: tools.floatDiv(this.spotCheckValue, 100),
                originalFiles: originalFiles,
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_detectorIndex_page_method_startTaskEvent_message_5'));
                    this.dialog.show = false;
                    this.initPage();
                }
            }).finally(()=>{
                this.dialog.loading = false;
            }) 
        },

        addTaskBtnEvent(type){
            this.dialog.show = true;
            this.dialog.type = type;
        },
        executeAction(key,params){
            switch (key){
                case 'excel':
                    this.downloadResult(params);
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    this.selectedTaskIds = [params.taskId];
                    break;
                case 'goto':
                    this.$router.push({path:'/tools/detector/detail',query:{id:params.taskId}});
                    break;
            }
        },
        downloadResult(params){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: params.title,
                metadataIdList: [params.taskId],
                metadataType: 'TOOLKIT_MTD',
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotice', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                
            })
        },
        deleteTask(){
            let url = toolsApi.deleteTask;
            this.$ajax.post(url,{
                ids:this.selectedTaskIds,
                toolType:'MTI'
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('tools.tools_detectorIndex_page_method_deleteTask_message_1'));
                    this.initPage();
                }
            })
        },
        exchangeLanguage(){
            let tmpOriginValue = this.dialog.source;
            let tmpTargetValue = this.dialog.target;
            this.dialog.source = tmpTargetValue;
            this.dialog.target = tmpOriginValue;
            this.initEngineConfig();
        },
        sourceSelectChange(value){
            if(value === this.dialog.target){
                if(value==='zh'){
                    this.dialog.target = 'en';
                }else{
                    this.dialog.target = 'zh';
                }
            }
            this.initEngineConfig();
            // this.setCheckTips();
        },
        targetSelectChange(){
            this.initEngineConfig();
        },
        inputSearchEvent(value){
            let self = this;
            if(value.length > 0){
                this.queryType = 'search';
            }else{
                this.queryType = 'init';
            }
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                self.initPage();
            },500);
        },
        typeRadioChange(item){
            this.typeRadioSelected = item.key;
        },
    },
    mounted(){
        let self = this;
        this.initEngineConfig();
        this.initPage('init');
        if(this.listTimer){
            clearInterval(this.listTimer);
        }
        this.listTimer = setInterval(()=>{
            self.initPage('reload');
        },5000);
    },
    destroyed(){
        clearInterval(this.listTimer);
    },   
}
</script>
<style lang="scss" scoped>
.detector-index-page{
    .dialog-content{
        > .tips{
            text-align: center;
            font-size: 12px;
            margin-bottom: 20px;
        }
        > .language-direction{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px; 
            svg{
                font-size: 20px;
                margin: 0 10px;
            }
        }
        > .upload-files-content{
        }
        > .upload-files-content-2{
            display: flex;
            > div{
                &:first-child{
                    padding-right: 40px;
                }
                flex: 1;
                min-width: 0;
            }
        }
        > .term-upload-content{
            background-color: #FDF5E6;
            border-radius: 8px;
            display: flex;
            padding: 10px 15px;
            margin: 30px 20px 20px 20px;
            .icon-content{
                color: #FDB500;
                font-size: 20px;
            }
            .info-content{
                margin-left: 5px;
                flex:1;
                font-size: 12px;
                line-height: 20px;
            }
            ::v-deep .el-upload-list__item-name{
                white-space: initial;
            }
        }
        .check-tips{
            font-size: 12px;
            text-align: center;
            margin-top: 10px;
        }
    }
    .detector-setting{
        margin-top: 20px;
        .block-title{
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .option-list{
            display: flex;
            margin-bottom: 25px;
            flex-wrap: wrap;
            .item{
                width: 25%;
                padding: 10px 0;
            }
        }
    }
}
</style>
